.houseMain {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;

	/* justify-content: center; */
	align-items: center;
	/* background-color: #2e5f6d; */

	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.houseMainImage {
	position: relative;
	display: flex;
	justify-content: center;
	max-width: 100vw;
	/* overflow: hidden; */
}

.houseMainImage > img {
	max-width: 100%;
	max-height: 100%;
	/* overflow: hidden; */
	object-fit: contain;
	/* object-fit: contain !important; */
	/* display: none; */
}

.housePhone {
	position: absolute;
	left: 3vw;
	bottom: 3vh;
	z-index: 3;
}

.housePhoneGreen,
.housePhoneRed {
	z-index: 3;
	position: absolute;
	width: 25px;
	height: 25px;
	bottom: 83px;
	background-color: #ffffff;
	border-radius: 50%;
}
.housePhoneGreen {
	left: 25px;
}
.housePhoneGreen.on {
	cursor: pointer;
	-webkit-animation:phone 1s linear;
    -moz-animation:phone 1s linear;
    animation:phone 1s linear;

	-webkit-animation-iteration-count: infinite;
}

.housePhoneRed {
	left: 152px;
}
.housePhoneRed.on {
	cursor: pointer;
}

.housePhoneGreen > img,
.housePhoneRed > img {
	width: 100%;
}

@-moz-keyframes phone {
	0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
	25% { -webkit-transform: rotate(30deg); transform:rotate(30deg); }
	50% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
    75% { -webkit-transform: rotate(-30deg); transform:rotate(-30deg); }
	100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
}
@-webkit-keyframes phone {
	0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
	25% { -webkit-transform: rotate(30deg); transform:rotate(30deg); }
	50% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
    75% { -webkit-transform: rotate(-30deg); transform:rotate(-30deg); }
	100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
}
@keyframes phone {
	0% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
	25% { -webkit-transform: rotate(30deg); transform:rotate(30deg); }
	50% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
    75% { -webkit-transform: rotate(-30deg); transform:rotate(-30deg); }
	100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); }
}

.houseButtonContainer {
	position: absolute;
	display: flex;
	width: 100vw;
	bottom: 30px;
	z-index: 3;
	justify-content: center;
}

.houseButtonContainer > div {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #E9BC00;
	padding: 12px 60px 9px 60px;
	border-radius: 60px;

	font-size: 14px;
	color: #282E64;
	font-weight: 600;
}

.houseNextButtonContainer {
	position: absolute;
	display: flex;
	right: 30px;
	bottom: 30px;
	justify-content: center;
	z-index: 3;
}

.houseNextButtonContainer > div {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #E9BC00;
	width: 225px ;
	padding: 16px 0px 16px 0px;
	border-radius: 60px;

	font-size: 17px;
	background-color: #282E64;
	font-weight: 600;
}

.houseModal {
	z-index: 5;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(40, 46, 100, 0.13);
	visibility: hidden;
	background-attachment: fixed;
}
.houseModal.show {
	visibility: visible;
}

.houseModal > div {
	position: relative;
	display: flex;
	width: 60vw;
	height: 80vh;
	justify-content: center;
	border: 4px solid #282E64;
	border-radius: 30px;
	background-color: #ffffff;
	padding: 13px;
}

.houseModalClose {
	position: absolute;
	cursor: pointer;
	right: 17px;
	top: 17px;
}

.itemGlobal {
	position: absolute;
	cursor: pointer;
	background-size: 100% 100%;
	visibility: hidden;
	z-index: 2;
	/* background-color: red; */
	/* opacity: 0.7; */
}
.itemGlobal.draw {
	visibility: visible;
}

.item-waterBottle {
	z-index: 1 !important;
}

.item-rings {
	z-index: 4 !important;
}

/* Modal */
.houseModalLivingRoomBrochure {
	display: flex;
	flex: 1;
}

.houseModalLivingRoomBrochureButton {
	display: flex;
	align-items: flex-end;
}
.houseModalLivingRoomBrochureButton > div {
	cursor: pointer;
}

.houseModalLivingRoomBrochureContainer {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;

	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.houseModalGlobal {
	display: flex;
	height: 60%;
	align-self: center;
	align-items: center;
}
.houseModalGlobalImage {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	margin: 0 20px;
}
.houseModalGlobalImage > div > img {
	max-height: 100%;
	object-fit: contain;
	max-width: 90%;
}
.houseModalGlobalImage > img {
	display: none;
}

.houseModalGlobalText {
	display: flex;
	width: 60%;
	height: 100%;
	flex-direction: column;
	/* overflow: auto; */
	/* align-items: center; */

	color: #282E64;
	font-size: 18px;
	margin-right: 20px;
	/* padding-right: 20px; */
}
.houseModalGlobalText.center {
	justify-content: center;
}

.houseModalGlobalText > div {
	overflow: auto;
	padding-right: 20px;
}
.houseModalGlobalTextImage {
	display: flex;
	flex: 1;
	justify-content: center;
}
.houseModalGlobalTextImage > img {
	max-width: 400px;
}

.houseModaAnnimatedContainer{
	display: flex;
	/* flex-direction: column; */
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 30px;
	visibility: hidden;
}

.houseModaAnnimatedContainer.actual {
	visibility: visible;
}

.houseModaAnnimated {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 30vw;
	height: 30vw;
	max-height: 60vh;
	border: 2px solid #9FABBD;
}

.houseModaAnnimated.slider {
	background-color: rgb(10, 10, 10);
}

.houseModaAnnimated > input {
	position: absolute;
	-webkit-appearance: none;
	margin: 10px;
	width: calc(100% - 20px);
	bottom: 10px;
	z-index: 10;
}

.houseModaAnnimated.slider *::-webkit-slider-runnable-track {
	background-color: #9FABBD !important;
}

.houseModaAnnimatedText {
	display: flex;
	width: 40%;
	max-height: 60vh;
	overflow: auto;
	flex-direction: column;
	align-items: center;
	margin-left: 20px;

	color: #282E64;
	font-size: 18px;
}

.houseModaAnnimatedKitchen {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 30vw;
	height: 30vw;
	max-height: 60vh;
	border: 2px solid #9FABBD;

	overflow: hidden;
}
.houseModaAnnimatedKitchen > div:first-of-type {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	-moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
    transform: scale(1,1);

	-moz-transition: all .5s;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.houseModaAnnimatedKitchen.heart > div:first-of-type {
	-moz-transform: scale(3,3);
    -webkit-transform: scale(3,3);
    transform: scale(3,3);

	transform-origin: 50% 30%;
}

.houseModaAnnimatedKitchen.harm > div:first-of-type {
	-moz-transform: scale(3,3);
    -webkit-transform: scale(3,3);
    transform: scale(3,3);

	transform-origin: 77% 48%;
}

.houseModaAnnimatedKitchenImage {
	visibility: hidden;
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #fff;
}
.houseModaAnnimatedKitchenImage.active {
	visibility: visible;
}

.houseModaAnnimatedKitchenZoom1 {
	cursor: pointer;
	position: absolute;
	bottom: 57%;
	left: calc(49% - 20px);
	width: 40px;
}
.houseModaAnnimatedKitchenZoom2 {
	cursor: pointer;
	position: absolute;
	bottom: 52%;
	right: calc(33% - 20px);
	width: 40px;
}

.houseModaAnnimatedKitchenZoomOut {
	cursor: pointer;
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 40px;

	z-index: 2;
}

.houseModaAnnimatedKitchenZoomOut > img,
.houseModaAnnimatedKitchenZoom2 > img,
.houseModaAnnimatedKitchenZoom1 > img {
	width: 100%;
}

.flip-book {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
  }

  .flip-book img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
	opacity: 0;
  }

.flip-book img.active {
	opacity: 1;
}

.flip-book img.preview {
	opacity: 1;
	z-index: 2;
}

.flip-book img.preview.done {
	opacity: 0;
}

.flip-book > div {
	z-index: 3;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 10px;
	bottom: 50px;
	cursor: pointer;
	width: 168px;
	height: 37px;
	background-color: #E9BC00;
	border-radius: 60px;

	font-size: 16px;
	color: #282E64;
	font-weight: 600;
}

.listText {
	display: flex;
}

.listText > div:first-of-type {
	min-width: 10px !important;
	min-height: 10px !important;
	max-width: 10px !important;
	max-height: 10px !important;
	border-radius: 10px;
	margin-top: 2px;
	margin-right: 15px;
	background-color: #9FABBD;
}

.listText.ref > div:first-of-type {
	margin-top: 0px;
	margin-right: 0px;
	max-height: 100px !important;
	min-width: 20px !important;
	max-width: 20px !important;
	border-radius: 0px;
	background-color: transparent !important;
}

.houseButtonContainerPhone,
.housePhoneMobile,
.houseSlideTuto {
	display: none;
}

@media only screen and (max-width : 768px) {
	.houseMain {
		max-height: calc(100vh - 70px) !important;
	}
	.houseMainImage {
		display: unset;
		overflow-x: scroll;
		overflow-y: hidden;
		/* background-color: #9FABBD; */
		/* max-height: calc(100vh - 70px - 10vh); */
	}
	.houseMainImage > img {
		height: 100%;
		max-width: none;
		margin: 0 15px !important;
		/* object-fit: unset */
	}

	.houseSlideTuto {
		z-index: 5;
		position: relative;
		display: flex;
		position: absolute;
		top: 0;
		width: 100%;
		background-color: rgb(40, 46, 100, 0.7);

		color: #fff;
		font-size: 18px;
	}
	.houseSlideTuto > div:first-of-type {
		width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.houseSlideTuto > div:first-of-type > div:first-of-type {
		position: relative;
		display: flex;
		justify-content: center;
		height: 65px;
		width: 100%;
		margin-bottom: 10px;
	}
	.houseSwipe {
		position: absolute;
		height: 100%;
	}
	.houseSwipe {
		z-index: 9;
	}
	.houseSwipe.img2 {
		/* margin-right: 80px; */
		left: calc(50% - 70px);
		opacity: 70%;
	}
	.houseSwipe.img3 {
		left: calc(50% - 110px);
		opacity: 50%;
	}
	.houseSwipe.img4 {
		left: calc(50% - 150px);
		opacity: 30%;
	}

	.housePhone {
		display: none;
	}
	.houseButtonContainer {
		display: none;
	}
	.houseNextButtonContainer {
		display: none;
	}

	.housePhoneMobile {
		display: flex;
		position: absolute;
		top: 20px;
		z-index: 3;
	}
	.housePhoneMobile > div:first-of-type > img {
		height: 55px;
	}

	.housePhoneMobile > .housePhoneGreen,
	.housePhoneMobile > .housePhoneRed {
		width: 35px;
		height: 35px;
		bottom: unset;
		left: unset;
		top: 10px;
	}

	.housePhoneMobile > .housePhoneGreen {
		left: calc(20% - 17px);
	}
	.housePhoneMobile > .housePhoneRed {
		right: calc(20% - 17px);
	}

	.houseButtonContainerPhone {
		display: flex;
		/* flex: 1; */
		width: 100%;
		min-height: 50px;
		margin: 10px 0px;

		color: #fff;
	}
	.houseButtonContainerPhone > div {
		cursor: pointer;
		display: flex;
		flex: 1;
		/* width: 100%; */
		margin: 0px 15px;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.houseButtonPhone {
		background-color: #E9BC00;
		padding: 12px 30px 9px 30px;
		border-radius: 60px;

		font-size: 12px;
		/* color: #282E64; */
		font-weight: 600;
	}
	.houseButtonPhone.multi {
		margin-right: 7.5px;
	}

	.houseNextButtonPhone {
		/* color: #E9BC00; */
		padding: 12px 30px 9px 30px;
		border-radius: 60px;

		font-size: 12px;
		background-color: #282E64;
		font-weight: 600;
	}
	.houseNextButtonPhone.multi {
		margin-left: 7.5px;
	}

	.houseModal {
		/* max-width: 100vw !important; */
		/* overflow: scroll; */
		height: 100% !important;
		background-color: rgb(40, 46, 100, 0.5);
	}

	.houseModal > div {
		border: none;
		width: 95%;
		height: 85%;
		padding: unset;
	}

	.houseModalGlobal {
		flex-direction: column;
		height: 90%;
	}
	.houseModalGlobalImage {
		display: flex;
		max-height: 20%;
	}
	.houseModalGlobalImage > div {
		display: none;
	}
	.houseModalGlobalImage > img {
		display: unset;
		max-height: 100%;
		max-width: 100%;
		object-fit: contain;
	}
	.houseModalGlobalText {
		display: flex;
		width: 90%;
		margin-top: 20px;
		text-align: center;
		/* background-color: antiquewhite; */

		/* overflow: auto; */
		overflow: hidden;

		margin-right: 0px;
	}

	.houseModalGlobalText > div {
		display: flex;
		flex-direction: column;
	}

	.houseModalGlobalTextImage > img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.houseModalClose {
		display: flex;
		justify-content: center;
		align-items: center;
		top: unset;
		bottom: 17px;
		width: 40px;
		height: 40px;
		background-color: #E9BC00;
		border-radius: 50%;
	}
	.houseModalClose.relative {
		position: relative;
		bottom: unset;
		right: unset;
	}

	.houseModalLivingRoomBrochure {
		flex-direction: column;
		align-items: center;
	}

	.houseModalLivingRoomBrochure > .houseModalGlobalText {
		font-size: 14px;
	}

	.houseModalLivingRoomBrochure > .houseModalGlobalText > div {
		display: flex;
		align-items: center;
	}

	.houseModalLivingRoomBrochure > .houseModalGlobalText img {
		width: 80%;
	}

	.right-1 > img,
	.left > img {
		max-width: 100%;
		max-height: 100%;
	}

	.listText {
		display: unset !important;
	}

	.listText > div:first-of-type {
		display: none;
	}

	.listText > div:last-of-type::before {
		content: "";
		margin-right: 2px;
	}

	.listText.ref > div:last-of-type::before {
		content: "";
		margin-right: 2px;
	}

	.listText.ref1 > div:last-of-type::before {
		content: "1.";
	}
	.listText.ref2 > div:last-of-type::before {
		content: "2.";
	}
	.listText.ref3 > div:last-of-type::before {
		content: "3.";
	}
	.listText.ref4 > div:last-of-type::before {
		content: "4.";
	}
	.listText.ref5 > div:last-of-type::before {
		content: "5.";
	}
	.listText.ref6 > div:last-of-type::before {
		content: "6.";
	}
	.listText.ref7 > div:last-of-type::before {
		content: "7.";
	}
	.listText.ref8 > div:last-of-type::before {
		content: "8.";
	}

	/* .right-1 {
		text-align: left;
		width: 80%;
		align-self: center;
	}

	.right-1 > div {
		display: flex;
		width: 100%;
		align-items: center;
		font-weight: 600;
		margin-bottom: 20px;
	}
	.right-1 > div > div {
		display: flex;
		height: calc(100%);
		width: 5px;
		background-color: #B32D24;
		margin-right: 7px;
	}

	.left {
		position: relative;
		text-align: left;
		width: 80%;
		align-self: center;
	}

	.left > div:first-of-type {
		position: absolute;
		left: 0;
		top: 0;
		width: 90vh;

		font-weight: 600;
		margin-bottom: 20px;
		transform: rotate(-90deg);
	}
	.left > div:first-of-type > div {
		display: flex;
		height: calc(100%);
		width: 5px;
		background-color: #B32D24;
		margin-right: 7px;
	}
	.left > div:last-of-type {
		margin-left: 20px;
	} */

	.houseModalGlobalBottom {
		display: flex;
		width: 90%;
		margin: 10px 0;
	}

	.houseModalGlobalBottomText {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		text-decoration: underline;
		font-weight: 600;
		font-size: 18px;
	}


	.houseModaAnnimatedContainer {
		/* height: 60%; */
		flex-direction: column;
		margin: unset;
		justify-content: unset;
		align-items: unset;
	}
	.houseModaAnnimatedKitchen {
		/* display: none; */
		width: 100%;
		height: 100%;
		border: none;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
	}

	.houseModaAnnimatedText {
		width: 100%;
		height: 100%;
		margin-left: unset;

		display: flex;
		padding-top: 20px;
		margin-bottom: 20px;
		text-align: center;

	}

	.houseModaAnnimatedKitchenImage {
		visibility: hidden;
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: red;
	}

	.houseModaAnnimated {
		width: 100%;
		height: 100%;
		border: none;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
	}
}