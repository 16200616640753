.contactMain {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	background-color: rgb(236, 238, 242);
	/* overflow:hidden */
}

.contactMain *::-webkit-scrollbar-track {
	border: 5px solid rgb(236, 238, 242);
}

.contactMain > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	max-width: 1200px;
}

.contactTitle {
	margin-top: 50px;
	margin-bottom: 70px;
	font-size: 25px;
	color: #282E64;
	font-weight: bold;
}

.contactContainer {
	display: flex;
	width: 90%;
	margin: 30px 0;
	color: #282E64;
}
.contactLogo {
	display: flex;
	width: 40%;
	justify-content: center;
}
.contactLogo > img {
	width: 80%;
	object-fit: contain;
}

.contactContainerText {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin-left: 90px;

	color: #282E64;
	font-size: 18.5px;
}

.contactContainerText > div:first-of-type {
	margin-top: 20px;
	display: flex;
	flex: 1;
}

.contactContainerText > div:first-of-type a {
	color: #282E64;
	text-decoration: underline;
}

.contactContainerText > div:last-of-type > a {
	margin-right: 30px;
}

.contactTextThanks {
	margin: 30px 0;
	color: #282E64;
	font-size: 18.5px;
}

.contactContainerLogo {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.contactContainerLogo > div {
	display: flex;
	/* flex: 1; */
	width: 20%;
}
.contactContainerLogo > div > img {
	width: 100%;
}

@media only screen and (max-width : 768px) {
	.contactTitle {
		margin: 30px 0;
	}

	.contactMain > div {
		width: 100%;
		text-align: center;
		font-weight: 500;
	}

	.contactContainer {
		flex-direction: column;
		align-items: center;
		margin: 0;
	}

	.contactLogo {
		width: 60%;
	}
	.contactLogo > img {
		width: 100%;
	}

	.contactContainerText {
		width: 100%;
		margin: 0;
		margin-top: 10px;
		font-weight: 600;
	}

	.contactContainerText > div:last-of-type {
		display: flex;
		justify-content: space-around;
		margin: 10px 0;
	}
	.contactContainerText > div:last-of-type > a {
		margin: 0;
	}

	.contactContainerLogo {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
	.contactContainerLogo > div {
		width: 60%;
		margin-top: 20px;
		margin-bottom: 10px;
	}
}