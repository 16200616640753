.headerMain {
	display: flex;
	padding: 25px 50px;
	height: 20px;
	overflow: hidden;
	justify-content: space-between;
	align-items: center;
}

.headerMenuIcon {
	display: flex;
	width: 100%;
	cursor: pointer;
}

.headerLogo {
	display: flex;
	width: 100%;
	cursor: pointer;
	justify-content: center;
}
.headerLogo > img {
	max-width: 188px;
	max-height: 100%;
	object-fit: contain;
}

.headerLang {
	display: flex;
	width: 100%;
	color: #282E64;
	align-items: flex-start;
	justify-content: flex-end;
}

.headerLang > span {
	cursor: pointer;
}

.headerLang > div {
	background-color: #282E64;
	margin: 0 10px;
	width: 1px;
	height: 15px;
}

.headerLangSelect,
.menuLangSelect {
	font-weight: 700;
	border-bottom: 2px solid #E2BC30;
}

.menu {
	position: fixed;
	z-index: 10;
	display: flex;
	flex-direction: column;
	left: 0;
	top: 0;
	min-height: calc(100vh - 50px);
	width: 10vw;
	padding: 25px 50px;
	background-color: #9FABBD;

	color: #282E64;
	/* font-weight: 600; */
	font-size: 16px;
}

.menuIcon {
	cursor: pointer;
	margin-bottom: 10vh;
}

.menu > span {
	cursor: pointer;
	margin-bottom: 25px;
}

.menuLine {
	width: 80%;
	height: 2px;
	margin-top: 10vh;
	margin-bottom: 25px;
	background-color: #E9BC00;
}

.menuItem.active {
	font-weight: 600;
}

.menuLang,
.menuIconMobile {
	display: none;
}

@media only screen and (max-width : 768px) {
	.headerMain {
		top: 0;
		position: sticky;
		padding: 25px 20px;
		background-color: #fff;
		z-index: 6;
	}
	.headerLang {
		display: none;
	}
	.headerLogo {
		justify-content: flex-end;
	}

	.menu {
		position: absolute;
		width: 100vw;
		top: 70px;
		padding: 0;
		align-items: center;
		min-height: calc(100vh - 70px);
		background-color: rgb(237, 237, 237);
	}
	.menuIcon {
		display: none;
	}
	.menuLine {
		margin-top: 5vh;
		margin-bottom: 5vh;
	}
	.menu > span {
		margin: 15px 0px;
	}
	.menu > span:first-of-type {
		margin-top: 45px;
	}

	.menuLang {
		display: flex;
		color: #282E64;
		align-items: flex-start;
		margin-top: 45px;
	}
	.menuLang > span {
		cursor: pointer;
	}
	.menuLang > div {
		background-color: #282E64;
		margin: 0 10px;
		width: 1px;
		height: 15px;
	}

	.menuIconMobile {
		display: flex;
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
	.menuIconMobile > img {
		height: 50px;
		width: 50px;
	}
}