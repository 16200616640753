.glossaryMain {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	background-color: rgb(236, 238, 242);
	/* overflow:hidden */
}

.glossaryMain *::-webkit-scrollbar-track {
	border: 5px solid rgb(236, 238, 242);
}

.glossaryTitle {
	margin-top: 50px;
	font-size: 25px;
	color: #282E64;
	font-weight: bold;
}

.glossaryContainer {
	display: flex;
	flex: 1;
	width: 80%;
	/* overflow-y: scroll; */
	margin: 30px 0;
	color: #282E64;
}

.glossaryContainer > div {
	/* margin-right: 30px; */
}

@media only screen and (max-width : 768px) {
	.glossaryContainer {
		width: 90%;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.glossaryContainer > div {
		margin-right: 0;
	}

	.glossaryContainer span {
		display: flex;
		flex-direction: column;
	}

	.glossaryContainer b {
		margin: 20px 0;
	}
	.glossaryContainer b:first-of-type {
		margin-top: 0;
	}
}