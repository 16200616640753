.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  min-width: 100vw;
  /* max-height: 100vh; */
  max-width: 100vw;
  height: 100% !important;
}

/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #E9BC00 #282E64;
  }
  
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}
  
*::-webkit-scrollbar-track {
	background: #282E64;
  border: 5px solid #fff;
}
  
  *::-webkit-scrollbar-thumb {
	background-color: #E9BC00;
	border-radius: 20px;
  }

*::-webkit-slider-runnable-track {
  /* height: 0px; */
  /* border: none; */
  /* border: 1px solid #282E64; */
  /* border-radius: 0; */
  height: 6px;
  /* cursor:ew-resize; */
  border-radius:1.5rem;
  background-color: #282E64;
}

*::-webkit-slider-thumb {
  z-index: 10;
  -webkit-appearance: none;
  appearance: none;
  width: 100px;
  margin-top: -5px;
  height: 15px;
  border-radius: 20px;
  background: #E9BC00;
  cursor: pointer;
}

/* *::-moz-range-track {
  background-color: #282E64;
}
*::-ms-track {
  background-color: #282E64;
} */

* a {
	text-decoration: none;
	color: #282E64;
}

* sup {
  font-size: 10px;
}