.referenceContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	width: 80%;
	margin: 30px 0;
	margin-bottom: 10%;
	color: #282E64;
}

@media only screen and (max-width : 768px) {
	.referenceContainer {
		text-align: center;
	}
}