.homeMain {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	background-color: rgb(36, 41, 90);
}

.homeContainerTitle {
	text-align: center;
	font-size: 23px;
	margin-top: 23px;
	margin-bottom: 28px;
	color: #D8FFF6;
}

.homeContainer {
	display: flex;
	/* flex: 1; */
	width: 100%;
}

.homeContainerLeft,
.homeContainerRight {
	display: flex;
	flex-direction: column;
	width: 20%;
}

.homeContainerCenter {
	display: flex;
	flex: 1;
	/* width: 100%; */
	justify-content: center;
	/* background-color: royalblue; */
}

.homeContainerRight {
	justify-content: center;
	text-align: right;

	font-size: 20px;
	font-weight: 100;
	color: #D8FFF6;
}
.homeContainerRight > span {
	margin-right: 5vw;
}

.homeContainerRightText {
	margin: 30px 0px;
}

.homeButtonContainer {
	display: flex;
	flex: 1;
	align-items: center;
}

.homeButtonContainer > div {
	cursor: pointer;
	background-color: #E9BC00;
	padding: 12px 60px 9px 60px;
	border-radius: 60px;

	font-size: 12px;
	color: #282E64;
	font-weight: 600;
}

@media only screen and (max-width : 768px) {
	.homeContainerTitle {
		text-align: center;
	}

	.homeContainer {
		flex: 1;
		flex-direction: column;
	}
	.homeContainerLeft {
		display: none;
	}
	.homeContainerCenter {
		height: 100%;
	}
	.homeContainerRight {
		flex: 1;
		width: 100%;
		text-align: center;
	}
	.homeContainerRight > span {
		margin-right: 0;
		font-weight: 500;
	}
	
	.homeContainerRightText {
		margin: 10px 0px;
	}

	.homeButtonContainer {
		margin: 10px 0;
	}
	.homeButtonContainer > div {
		color: #fff;
	}
}